@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

img {
  max-width: 100%;
  max-height: 60%;
}

.spacer25 {
  height: 25px;
}

.teste {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  aspect-ratio: 1;
  border-radius: 50%;
  outline: none;
  border: none;
  box-shadow: 0 0 8px transparent;
  background-color: #f3f3f3;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background-color 0.4s, box-shadow 0.4s;
  margin-top: 20px;
  margin-bottom: 20px;
}

.controlsMain {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  height: 100vh;
}

.controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
  margin-right: 2rem;
}

.controls button {
  font-family: "DM Sans", sans-serif;
  background-color: #cab9ac;
  color: #4b4b4b;
  accent-color: #969696;
}
.controls button:hover {
  font-family: "DM Sans", sans-serif;
  background-color: #66a487;
  color: #4b4b4b;
  accent-color: #969696;
}

.btnAnimation {
  margin-bottom: 1rem !important;
  width: 100%;
}

.modelDiv {
  width: 85%;
  display: flex;
}

.btnBottomDiv {
  border-top: 1px solid #000;
  position: absolute;
  bottom: 0;
  height: 15%;
  width: 100%;
  display: none;
  overflow-y: scroll;
}

.octagon {
  clip-path: polygon(
    30% 0%,
    70% 0%,
    100% 30%,
    100% 70%,
    70% 100%,
    30% 100%,
    0% 70%,
    0% 30%
  );
  height: 60px;
  width: 60px;
}
@media only screen and (max-width: 768px) {
  .btnBottomDiv {
    padding: 10px;
    display: block;
    text-align: center;
  }

  .controlsMain {
    display: none;
  }

  .modelDiv {
    width: 100%;
    height: 85%;
  }

  .btnAnimation {
    width: 200px;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .octagonDiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .octagon {
    height: 75px;
    width: 75px;
    margin: 2rem;
  }
}
/* HOME */

.homepageDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: black;
}

/* .homepageDiv h2{
  position:relative;
  font-size: 5rem;
  font-weight: 600;
  letter-spacing: -5px;
  color: rgba(0,254,254,1);
}

.homepageDiv h2:after{
  content: "Hubduction";
  position: absolute;
  left: 2px;
  top: 3px;
  color: rgba(254,0,2,1);
  /* mix-blend-mode: multiply;  
} */

.homepageDiv h2 {
  color: hsla(0, 0%, 0%, 0.9);
  font: normal 140px Varela Round, sans-serif;
  height: 140px;
  left: 0;
  letter-spacing: 5px;
  margin: -80px 0 0 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 50%;
  width: 100%;
  animation: move linear 2000ms infinite;
}

@keyframes move {
  0% {
    text-shadow: 4px -4px 0 hsla(0, 100%, 50%, 1),
      3px -3px 0 hsla(0, 100%, 50%, 1), 2px -2px 0 hsla(0, 100%, 50%, 1),
      1px -1px 0 hsla(0, 100%, 50%, 1), -4px 4px 0 hsla(180, 100%, 50%, 1),
      -3px 3px 0 hsla(180, 100%, 50%, 1), -2px 2px 0 hsla(180, 100%, 50%, 1),
      -1px 1px 0 hsla(180, 100%, 50%, 1);
  }
  25% {
    text-shadow: -4px -4px 0 hsla(180, 100%, 50%, 1),
      -3px -3px 0 hsla(180, 100%, 50%, 1), -2px -2px 0 hsla(180, 100%, 50%, 1),
      -1px -1px 0 hsla(180, 100%, 50%, 1), 4px 4px 0 hsla(0, 100%, 50%, 1),
      3px 3px 0 hsla(0, 100%, 50%, 1), 2px 2px 0 hsla(0, 100%, 50%, 1),
      1px 1px 0 hsla(0, 100%, 50%, 1);
  }
  50% {
    text-shadow: -4px 4px 0 hsla(0, 100%, 50%, 1),
      -3px 3px 0 hsla(0, 100%, 50%, 1), -2px 2px 0 hsla(0, 100%, 50%, 1),
      -1px 1px 0 hsla(0, 100%, 50%, 1), 4px -4px 0 hsla(180, 100%, 50%, 1),
      3px -3px 0 hsla(180, 100%, 50%, 1), 2px -2px 0 hsla(180, 100%, 50%, 1),
      1px -1px 0 hsla(180, 100%, 50%, 1);
  }
  75% {
    text-shadow: 4px 4px 0 hsla(180, 100%, 50%, 1),
      3px 3px 0 hsla(180, 100%, 50%, 1), 2px 2px 0 hsla(180, 100%, 50%, 1),
      1px 1px 0 hsla(180, 100%, 50%, 1), -4px -4px 0 hsla(0, 100%, 50%, 1),
      -3px -3px 0 hsla(0, 100%, 50%, 1), -2px -2px 0 hsla(0, 100%, 50%, 1),
      -1px -1px 0 hsla(0, 100%, 50%, 1);
  }
  100% {
    text-shadow: 4px -4px 0 hsla(0, 100%, 50%, 1),
      3px -3px 0 hsla(0, 100%, 50%, 1), 2px -2px 0 hsla(0, 100%, 50%, 1),
      1px -1px 0 hsla(0, 100%, 50%, 1), -4px 4px 0 hsla(180, 100%, 50%, 1),
      -3px 3px 0 hsla(180, 100%, 50%, 1), -2px 2px 0 hsla(180, 100%, 50%, 1),
      -1px 1px 0 hsla(180, 100%, 50%, 1);
  }
}

/* MODEL CONTROL BUTTON */

/* SHOE PAGE */
.picker {
  position: absolute !important;
  top: 74px;
  left: 70px;
  width: 90px !important;
  height: 90px !important;
}

.pickerDiv {
  position: absolute;
  top: 10%;
  background-color: red !important;
}

.current_name {
  position: absolute;
  top: 43px;
  left: 140px;
  padding: 0;
  margin: 40px;
  font-size: 5em;
  font-weight: 600;
  line-height: 0.7em;
  letter-spacing: -6px;
  color: #272730;
}
/* SHOE PAGE */
